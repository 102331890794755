<template>
  <div class="container" v-loading="loading"> 
    <keep-alive>
      <component :is='currentComponent' :params="content" @getMsg="toDo(arguments)"></component>  
    </keep-alive>
  </div>
</template>
<script>
import CreateScreen from './create'
import BuildScreen from './build'
export default {
  name: 'container',  
  components:{
    CreateScreen,
    BuildScreen
  },
  data () {
    return {
      loading: false,
      currentComponent: 'CreateScreen',
      content: {}    
    };
  },
  mounted () {
    
  },   
  methods: {    
    toDo(data){
        const self = this;
        if(data[0] == 'create'){
          this.currentComponent = 'CreateScreen';
        }else if(data[0] == 'list'){
          this.$emit('getMsg',true);
        }else if(data[0] == 'build') {
          self.content = data[1];
          this.currentComponent = 'BuildScreen';            
        }
    }
  }
};
</script>
<style lang="scss" scoped>
.container{
    z-index:2000;
    width:100%;
    height:100%;
    margin:0;
    top:0;
    right:0;
    bottom:0;
    left:0;
    position:fixed;
    //background-color:#000A3B;
    background-color:#1d1e1f;
}
</style>
